<template>
  <div v-bind="attributes.top" class="c-bitts-textarea">
    <BittsFormLabel
      v-if="formLabel"
      class="c-bitts-textarea__label"
      :label="formLabel"
      :disabled="disabled"
    />
    <Input.TextArea
      v-bind="attributes.input"
      :disabled="disabled"
      :placeholder="placeholder"
      :value="modelValue"
      :rows="rowCount"
      class="c-bitts-textarea"
      :class="{
        danger: status === 'danger',
        success: status === 'success',
        disabled: disabled,
      }"
      @input="emit('update:modelValue', $event.target.value)"
    />
    <BittsStatus
      v-if="status !== 'default'"
      :status="status"
      :disabled="disabled"
      :success-text="successText"
      :danger-text="dangerText"
    />
  </div>
</template>
<script setup lang="ts">
import { Input } from 'ant-design-vue';
import { computed, useAttrs } from 'vue';

import BittsFormLabel from '../BittsFormLabel/BittsFormLabel.vue';
import { FormLabelType } from '../BittsFormLabel/types';
import BittsStatus, {
  BittsStatusDefaults,
  BittsStatusProps,
} from '../BittsStatus/BittsStatus.vue';

export type Props = BittsStatusProps & {
  modelValue?: string;
  disabled?: boolean;
  rowCount?: number;
  placeholder?: string;
  formLabel?: FormLabelType | string;
};

withDefaults(defineProps<Props>(), {
  modelValue: '',
  rowCount: 4,
  placeholder: '',
  formLabel: '',
  ...BittsStatusDefaults,
});

const emit = defineEmits(['update:modelValue']);

defineOptions({ inheritAttrs: false });
const rawAttrs = useAttrs();
const attributes = computed(() => {
  const { class: className, ...rest } = rawAttrs;
  return { top: { class: className }, input: { ...rest } };
});
</script>

<style lang="pcss">
.c-bitts-textarea {
  font-family: inherit;
}
textarea.c-bitts-textarea.ant-input::placeholder {
  @apply text-neutral-text-placeholder;
}

textarea.c-bitts-textarea.ant-input {
  @apply rounded-8 border-neutral-border shadow-component outline outline-1 w-full py-10 min-h-[45px] text-neutral-text outline-transparent;
  font-family: inherit;

  &:hover,
  &:focus {
    @apply outline-1 border-neutral-border-focus outline-neutral-border-focus;
  }

  &.disabled {
    @apply bg-neutral-background-disabled outline-neutral-border border-neutral-border;
    &:hover,
    &:focus {
      @apply outline-0;
    }
  }

  &.success:not(.disabled) {
    @apply outline-success-accent border-success-accent outline-1;
  }

  &.danger:not(.disabled) {
    @apply border-danger-accent outline-danger-accent outline-1;
  }
}
</style>
